<template>
    <main
        class="page-content"
        role="main"
        v-if="(appReference && loading) || !appReference"
    >
        <div class="container-fluid">
            <form class="form-card">
                <div class="row justify-center">
                    <div class="col-md-12 col-lg-12 col-xl-6">
                        <panel id="panel-1 ">
                            <template v-slot:header>
                                <div class="my-2 is-flex">
                                    <span
                                        class="icon-stack fs-xxl mr-2 d-flex justify-content-center align-items-center"
                                    >
                                        <i
                                            class="base base-7 icon-stack-4x opacity-100 color-primary-700"
                                        ></i>
                                        <i
                                            class="fas fa-cog icon-stack-2x opacity-100 color-white"
                                        ></i>
                                    </span>
                                    <h2 v-if="appReference">
                                        {{
                                            $t(
                                                'forms.applications.title_update'
                                            )
                                        }}
                                        {{
                                            `"${
                                                application.name
                                                    ? application.name
                                                    : null
                                            }"`
                                        }}
                                    </h2>
                                    <h2 v-else>
                                        {{
                                            $t(
                                                'forms.applications.title_create'
                                            )
                                        }}
                                    </h2>
                                </div>
                            </template>
                            <div
                                class="panel-container collapse show p-4"
                                style=""
                            >
                                <div class="row">
                                    <div
                                        class="col-md-12 col-lg-12 col-xl-12 mt-5"
                                    >
                                        <div class="form-group">
                                            <label
                                                class="form-label"
                                                for="appName"
                                            >
                                                {{
                                                    $t(
                                                        'forms.applications.name'
                                                    )
                                                }}</label
                                            >
                                            <input
                                                id="appName"
                                                v-model="application.name"
                                                required
                                                class="form-control"
                                                type="text"
                                            />
                                            <p
                                                v-if="isSend && nameMessage"
                                                class="has-text-danger font-bold text-center is-size-7"
                                            >
                                                {{ nameMessage }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div
                                        class="col-md-12 col-lg-12 col-xl-12 mt-5"
                                    >
                                        <div class="form-group">
                                            <label
                                                class="form-label"
                                                for="appClientURL"
                                            >
                                                {{
                                                    $t(
                                                        'forms.applications.client_url'
                                                    )
                                                }}
                                            </label>
                                            <input
                                                id="appClientURL"
                                                v-model="application.client_url"
                                                class="form-control"
                                                type="url"
                                            />
                                        </div>
                                        <p
                                            v-if="isSend && clientUrlMessage"
                                            class="has-text-danger font-bold text-center is-size-7"
                                        >
                                            {{ clientUrlMessage }}
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div
                                        class="col-md-12 col-lg-12 col-xl-12 mt-5"
                                    >
                                        <div class="form-group">
                                            <label
                                                class="form-label"
                                                for="appSource"
                                            >
                                                {{
                                                    $t(
                                                        'forms.applications.source'
                                                    )
                                                }}
                                            </label>
                                            <input
                                                id="appSource"
                                                v-model="application.source"
                                                required
                                                class="form-control"
                                                type="email"
                                            />
                                        </div>
                                        <p
                                            v-if="isSend && sourceMessage"
                                            class="has-text-danger font-bold text-center is-size-7"
                                        >
                                            {{ sourceMessage }}
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div
                                        class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                    >
                                        <div class="form-group">
                                            <label
                                                class="form-label"
                                                for="appLang"
                                            >
                                                {{
                                                    $t(
                                                        'forms.applications.lang.label'
                                                    )
                                                }}
                                            </label>
                                            <select
                                                v-model="application.lang"
                                                id="appLang"
                                                name="appLang"
                                                class="form-control select"
                                            >
                                                <option value="fr">
                                                    {{
                                                        $t(
                                                            'forms.applications.lang.fr'
                                                        )
                                                    }}
                                                </option>
                                                <option value="en">
                                                    {{
                                                        $t(
                                                            'forms.applications.lang.en'
                                                        )
                                                    }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div
                                        class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                    >
                                        <div class="form-group">
                                            <label
                                                class="form-label"
                                                for="appLang"
                                            >
                                                {{
                                                    $t(
                                                        'forms.applications.active.label'
                                                    )
                                                }}
                                            </label>
                                            <select
                                                v-model="application.active"
                                                id="appLang"
                                                name="appLang"
                                                class="form-control select"
                                            >
                                                <option value="1">
                                                    {{
                                                        $t(
                                                            'forms.applications.active.1'
                                                        )
                                                    }}
                                                </option>
                                                <option value="0">
                                                    {{
                                                        $t(
                                                            'forms.applications.active.0'
                                                        )
                                                    }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </panel>
                        <div class="d-flex justify-center">
                            <button
                                class="btn btn-primary"
                                @click.prevent="createOrUpdateApplication()"
                                :disabled="isSendLoading"
                            >
                                <i
                                    v-show="isSendLoading"
                                    class="fas fa-circle-notch fa-spin"
                                ></i>
                                {{ $t('forms.applications.send') }}
                            </button>
                        </div>
                        <div class="d-flex justify-center" v-if="isChangeSaved">
                            <p>
                                {{ $t('forms.applications.saved') }}
                            </p>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </main>
</template>

<script>
import Panel from '@/components/UI/Panel'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'FormApplications',
    components: {
        Panel
    },
    data() {
        return {
            appReference: '',
            formId: null,
            loading: false,
            isChangeSaved: false,
            isSend: false,
            isSendLoading: false,
            nameMessage: '',
            sourceMessage: '',
            clientUrlMessage: '',
            regex: 'https?:\\/\\/(?:www\\.)?([-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b)*(\\/[\\/\\d\\w\\.-]*)*(?:[\\?])*(.+)*'
        }
    },
    created() {
        this.appReference = this.$route.params.reference
        this.formId = this.$route.params.id
        this.appReference ? this.getAppData() : null
    },
    computed: {
        application: {
            get() {
                return this.$store.state.application.application
            },
            set(value) {
                this.$store.commit('application/setApplication', value)
            }
        }
    },
    methods: {
        ...mapActions('application', [
            'getApplication',
            'createApplication',
            'editApplication'
        ]),
        getAppData() {
            this.getApplication({ formId: this.formId, id: this.appReference })
                .then(() => {
                    this.loading = true
                })
                .catch((error) => {
                    this.loading = false
                    this.$store.dispatch('dispatchError', error)
                })
        },
        createOrUpdateApplication() {
            this.confirmName()
            this.confirmSource()
            this.confirmClientUrl()
            this.isSend = true
            this.isSendLoading = true
            let endpoint = this.appReference
                ? this.editApplication({
                      formId: this.formId,
                      id: this.appReference
                  })
                : this.createApplication(this.formId)

            !this.nameMessage && !this.sourceMessage && !this.clientUrlMessage
                ? endpoint
                      .then(() => {
                          this.isChangeSaved = true
                          this.isSendLoading = false
                          this.$router.push(`/forms/${this.formId}`)
                      })
                      .catch((error) => {
                          this.isChangeSaved = false
                          this.isSendLoading = false
                          this.$store.dispatch('dispatchError', error)
                      })
                : (this.isSendLoading = false)
        },
        confirmName() {
            if (!this.application.name) {
                this.nameMessage = this.$t('error.validation.required')
            } else {
                this.nameMessage = ''
            }
        },
        confirmSource() {
            if (!this.application.source) {
                this.sourceMessage = this.$t('error.validation.required')
            } else {
                this.sourceMessage = ''
            }
        },
        confirmClientUrl() {
            if (!this.application.client_url) {
                this.clientUrlMessage = this.$t('error.validation.required')
            } else if (!this.application.client_url.match(this.regex)) {
                this.clientUrlMessage = this.$t('error.validation.url_valid')
            } else {
                this.clientUrlMessage = ''
            }
        }
    }
}
</script>
<style scoped>
.select {
    border: 2px solid #3d4d5d !important;
    font-size: 0.8em;
    height: 2.9em;
}
</style>
